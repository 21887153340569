import React, { useState } from "react";
import { instantiateLiveFormApply } from "helpers/hotwire_helpers/LiveFormHelper";

const HideSavedCandidatesToggle = ({ existingSelectedOptions }) => {
  const [hideSavedCandidates, setHideSavedCandidates] = useState(existingSelectedOptions);

  instantiateLiveFormApply(hideSavedCandidates);

  return (
    <div className="toggle">
      <input type="checkbox"
             readOnly
             name="hide_saved_candidates"
             value={hideSavedCandidates}
             checked={hideSavedCandidates}
             autoComplete="off" />

      <label checked="checked">
        <div className="toggle__slider" onClick={() => setHideSavedCandidates(!hideSavedCandidates)}></div>

        Hide saved candidates
      </label>
    </div>
  )
};

export default HideSavedCandidatesToggle;
